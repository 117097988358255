import Vue from 'vue'
import Vuex from 'vuex'
import ky from 'ky'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    user: null,
    providers: null,
    ky: ky.extend({
      prefixUrl: process.env.VUE_APP_BASE_URL,
      credentials: 'include',
      timeout: 25000,
      retry: {
        limit: 1,
      },
      hooks: {
        afterResponse: [
          async (_request, _options, response) => {
            if (response.status >= 400) {
              let data
              try {
                data = await response.json()
                // eslint-disable-next-line no-empty
              } catch (e) { }
              throw new Error(data?.error ?? data?.message ?? 'unknownError')
            }
          }
        ]
      }
    }),
  }),
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setProviders(state, providers) {
      state.providers = providers
    },
  },
  actions: {},
  modules: {},
})
