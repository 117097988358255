import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Energy',
    component: () => import(/* webpackChunkName: "stats" */ '../views/Energy'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path: '/temperature',
    name: 'Temperature',
    component: () => import(/* webpackChunkName: "temperature" */ '../views/Temperatures'),
  },
  {
    path: '/pastes',
    name: 'Pastes',
    component: () => import(/* webpackChunkName: "Images" */ '../views/Paste/Pastes'),
  },
  {
    path: '/oauth/:provider',
    name: 'OAuthCallback',
    props: true,
    component: () => import(/* webpackChunkName: "oauth-callback" */ '../views/OAuth/Callback'),
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "Images" */ '../views/Paste/Upload'),
  },
  {
    path: '/pastes/:code',
    name: 'PasteDetail',
    props: true,
    component: () => import(/* webpackChunkName: "PasteDetail" */ '../views/Paste/PasteDetail'),
  },
  {
    path: '/mc',
    name: 'McDonalds',
    component: () => import(/* webpackChunkName: "McDonalds" */ '../views/McDonalds'),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  const { user } = store.state
  const allowedRoutes = [
    'McDonalds'
  ]

  if (allowedRoutes.includes(to.name)) {
    next()
  }
  else if (user === null && (to.name !== 'Login' && to.name !== 'OAuthCallback')) {
    next({
      name: 'Login',
    })
  } else if (user !== null && to.name === 'Login') {
    next({
      name: 'Home',
    })
  } else {
    next()
  }
})

export default router
