import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

store.state.ky.get('oauth/providers').json()
  .then(providers => store.commit('setProviders', providers))

store.state.ky.post('auth/login/check').json().then((user) => {
  store.commit('setUser', user)
}).catch(() => {

}).finally(() => {
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
