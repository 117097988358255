<template>
  <v-app id="inspire">
    <component :is="layoutComponent">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
export default {
  components: {
    'login-layout': () => import('@/layouts/Login.vue'),
    'navigation-layout': () => import('@/layouts/Navigation.vue'),
  },
  computed: {
    layoutComponent() {
      if (this.$store.state.user !== null)
        return 'navigation-layout'
      else
        return 'login-layout'
    },
  },
}
</script>

<style lang="scss">
//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}

</style>
